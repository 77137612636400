import logo from './img/logo.svg';
import groep from './img/groepsfoto.jpg'
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="header">
                <img src={logo}/>
            </header>
            <main>
                <div className="banner">
                    <img src={groep}/>
                </div>
                <div className="intro">
                    <div className="intro__text">
                        <h1 className={'libre-baskerville-bold'}>Welkom bij Sint Antoniusgilde Stiphout</h1>
                        <p className={'quicksand'}>Het Sint Antoniusgilde is de oudste vereniging van Stiphout. De
                            exacte oprichtingsdatum is
                            niet bekend. De zilveren koningsvogel op het vest van de koning dateert uit 1610. Het oudste
                            officiële document is getekend in 1711 maar vermoedelijk bestond het gilde al in de 15e
                            eeuw.</p>
                        <p className={'quicksand'}>Het gilde is een historische vereniging waarbij gezelligheid, broederschap en
                            onderling samenzijn voorop staat. Als gilde dragen wij graag bij aan de gemeenschapszin van
                            ons dorp Stiphout.</p>

                    </div>
                </div>

            </main>
        </div>
    );
}

export default App;
